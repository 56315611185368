import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import IBDIOOLogo
    from "../../images/Careers/deeplai-career-international_business_development_manager_indirect_oem_operations.svg";

const CareersManagerIndirectOEMOperations = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.internationalBusinessDevelopmentManagerIndirectOemOperations) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (
        <OfferPage
            image={IBDIOOLogo}
            imageAlt={'deeplai career International Business Development Manager Indirect OEM Operations'}
            name={routes.CareersManagerIndirectOEMOperations.pageTitle}
            scopeOfDuties={<><p>Acquire and manage Indirect Business Partners (BP)</p><p>Establish,
                develop and maintain positive business relationships with BP Owners, management </p>
                <p>Achieve agreed upon sales targets and outcomes within schedule</p><p>Analyze the
                    market’s potential, track sales and status reports</p><p>Make technical sales
                    presentations training for BP and demonstrate how our SaaS solutions are meeting
                    Customers requirements</p><p>Help in the design of custom-made solutions</p>
                <p>Provide a BP with pre-sales technical assistance and solutions education</p><p>Supply
                    management with reports on BP needs, problems, potential for new solutions
                    developments </p><p>Keep abreast of competitive market offerings</p></>}
            ourRequirements={<><p>Bachelor’s degree </p><p>Proven work experience as a Business
                Development ManagerDM</p><p>Highly motivated and target driven with a proven track
                record in sales</p><p>Excellent selling, communication and negotiation skills</p>
                <p>Prioritizing, time management and organizational skills</p><p>Ability to create and
                    deliver presentations tailored to the audience Leeds</p><p>Relationship management
                    skills and openness to feedback</p><p>Fluency in English</p></>}
        />
    );
};

export default CareersManagerIndirectOEMOperations;
